<template>
    <div class="participation" id="pdf6">
        <div class="cephalosome flex-align-between">
            <div class="Secondary_heading">
                <div class="vertical_grain_icon"></div> <span class="title">全校各项成绩对比</span>
            </div>
        </div>
        <div class="substance  School_substance">
            <div class="performance_proportion table_scrollbar">
                <el-table :data="tablaData_school" style="width: 100%" :stripe="true" empty-text="暂无数据">
                    <el-table-column prop="indexRank" label="一级维度" align="center">
                    </el-table-column>
                    <el-table-column prop="layoutScore" label="章法占格" align="center">
                        <template slot-scope="scope">
                            <span style="margin-left: 10px">{{ scope.row.layoutScore == null ? '/' :
                                scope.row.layoutScore.toFixed(2) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="structureScore" label="结构组合" align="center">
                        <template slot-scope="scope">
                            <span style="margin-left: 10px">{{ scope.row.structureScore == null ? '/' :
                                scope.row.structureScore.toFixed(2) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="formScore" label="笔画形态" align="center">
                        <template slot-scope="scope">
                            <span style="margin-left: 10px">{{ scope.row.formScore == null ? '/' :
                                scope.row.formScore.toFixed(2) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="completeScore" label="作品完整度" align="center">
                        <template slot-scope="scope">
                            <span style="margin-left: 10px">{{ scope.row.completeScore == null ? '/' :
                                scope.row.completeScore.toFixed(2) }}</span>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div class="performance_graphics">
                <various_graphics :seriesData="various_graphics_data" :variousData="variousData" :variousType="'月测'">
                </various_graphics>
            </div>
        </div>
        <div class="semester flex-column-center">
            <h1>与上次月测等级对比</h1>
            <div class="semester_table table_scrollbar">
                <el-table :data="tableData_compare" style="width: 100%" :stripe="true" empty-text="暂无数据">
                    <el-table-column prop="indexRank" label="上次月测对比" align="center"> </el-table-column>
                    <el-table-column label="章法占格" align="center">
                        <el-table-column prop="parentId1ben" label="本次月测" align="center">
                            <template slot-scope="scope">
                                <div class="imgUrlbox" v-if="scope.row.parentId1_compare && scope.row.parentId1ben != 0">
                                    <img :src="imgUrl[`imgUrl${scope.row.parentId1_compare}`]" alt="">
                                </div>
                                <span class="tableDataZ" style="margin-left: 10px">{{ contrast(scope.row.parentId1ben,'本次月测')}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="parentId1last" label="上次月测" align="center">
                            <template slot-scope="scope">
                                <span>{{contrast(scope.row.parentId1last,'上次月测')}}</span>
                            </template>
                        </el-table-column>
                    </el-table-column>
                    <el-table-column label="结构组合" align="center">
                        <el-table-column prop="parentId2ben" label="本次月测" align="center">
                            <template slot-scope="scope">
                                <div class="imgUrlbox" v-if="scope.row.parentId2_compare && scope.row.parentId2ben != 0">
                                    <img :src="imgUrl[`imgUrl${scope.row.parentId2_compare}`]" alt="">
                                </div>
                                <span class="tableDataZ" style="margin-left: 10px">{{ contrast(scope.row.parentId2ben,'本次月测') }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="parentId2last" label="上次月测" align="center">
                            <template slot-scope="scope">
                                <span>{{ contrast(scope.row.parentId2last,'上次月测')}}</span>
                            </template>
                        </el-table-column>
                    </el-table-column>
                    <el-table-column label="笔画形态" align="center">
                        <el-table-column prop="parentId3ben" label="本次月测" align="center">
                            <template slot-scope="scope">
                                <div class="imgUrlbox" v-if="scope.row.parentId3_compare && scope.row.parentId3ben != 0">
                                    <img :src="imgUrl[`imgUrl${scope.row.parentId3_compare}`]" alt="">
                                </div>
                                <span class="tableDataZ" style="margin-left: 10px">{{ contrast(scope.row.parentId3ben,'本次月测') }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="parentId3last" label="上次月测" align="center">
                            <template slot-scope="scope">
                                <span>{{contrast(scope.row.parentId3last,'上次月测') }}</span>
                            </template>
                        </el-table-column>
                    </el-table-column>
                    <el-table-column label="作品完整度" align="center">
                        <el-table-column prop="parentId4ben" label="本次月测" align="center">
                            <template slot-scope="scope">
                                <div class="imgUrlbox" v-if="scope.row.parentId4_compare && scope.row.parentId4ben != 0">
                                    <img :src="imgUrl[`imgUrl${scope.row.parentId4_compare}`]" alt="">
                                </div>
                                <span class="tableDataZ" style="margin-left: 10px">{{contrast(scope.row.parentId4ben ,'本次月测')}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="parentId4last" label="上次月测" align="center">
                            <template slot-scope="scope">
                                <span>{{contrast(scope.row.parentId4last,'上次月测')}}</span>
                            </template>
                        </el-table-column>
                    </el-table-column>
                </el-table>
            </div>
        </div>
        <div class="semester_conclusion conclusion" v-if="comment5">
            <span class="prefix_title">结论3</span><br />
            <span>(1) 章法占格维度：平均成绩处于<span class="Comment_highlight">{{ comment5.k1 }}</span>水平，
                <span class="Comment_highlight">{{
                    computedComment5(JSON.parse(JSON.stringify(comment5.resultDiffList)),
                        'parentId1ben') }}较好</span>。<br />
                (2) 结构组合维度：平均成绩处于<span class="Comment_highlight">{{ comment5.k3 }}</span>水平，
                <span class="Comment_highlight">{{
                    computedComment5(JSON.parse(JSON.stringify(comment5.resultDiffList)),
                        'parentId2ben') }}较好</span>。<br />
                (3) 笔画形态维度：平均成绩处于<span class="Comment_highlight">{{ comment5.k5 }}</span>水平，
                <span class="Comment_highlight">{{
                    computedComment5(JSON.parse(JSON.stringify(comment5.resultDiffList)),
                        'parentId3ben') }}较好</span>。<br />
                (4) 完整度维度：平均成绩处于<span class="Comment_highlight">{{ comment5.k7 }}</span>水平，
                <span class="Comment_highlight">{{
                    computedComment5(JSON.parse(JSON.stringify(comment5.resultDiffList)),
                        'parentId4ben') }}较好</span>。<br />
            </span>
            <p>相较上次月测，在
                <span class="Comment_highlight"> 章法占格 </span>维度，
                {{ computedLastTerm(comment5.resultDiffList, 'parentId1') }}；
                <span class="Comment_highlight">结构组合</span>维度，
                {{ computedLastTerm(comment5.resultDiffList, 'parentId2') }}；
                <span class="Comment_highlight">笔画形态</span>维度，
                {{ computedLastTerm(comment5.resultDiffList, 'parentId3') }}；
                <span class="Comment_highlight">完整度</span>维度，
                {{ computedLastTerm(comment5.resultDiffList, 'parentId4') }}。
            </p>
        </div>
    </div>
</template>

<script>
import various_graphics from './appraisalCharts/various_graphics.vue'
export default {
    //import引入组件才能使用
    components: {
        various_graphics,
    },
    props: {},
    data() {
        return {
            //全校成绩对比
            tablaData_school: [],
            various_graphics_data: [0, 0, 0, 0],
            //雷达图
            variousData: [{ name: '章法占格', max: 100 },
            { name: '结构组合', max: 100 },
            { name: '笔画形态', max: 100 },
            { name: '作品完整度', max: 100 }],
            //与上次月测等级对比
            tableData_compare: [],
            comment5: null,
            imgUrl: {
                imgUrl1: require('@/assets/newReport/greent.png'),
                imgUrl2: require('@/assets/newReport/or_r.png'),
                imgUrl3: require('@/assets/newReport/redx.png'),
            },
        };
    },
    // 计算属性
    computed: {},
    // 监听data中的数据变化
    watch: {},
    // 方法集合
    methods: {
        parameterall1(tableData, graphicData) {
            this.tablaData_school = tableData;
            this.various_graphics_data = graphicData;
        },
        parameterall2(tableData, commentData) {
            this.tableData_compare = tableData;
            this.comment5 = commentData;
        },
        // 结论5 计算各维度成绩前两名
        computedComment5(list, name) {
            let element = [];
            for (let index = 0; index < list.length; index++) {
                element.push(list[index][name]);
            }
            let Mathdata = Math.max(...element)

            const indices = element.reduce((acc, value, index) => {
                if (value === Mathdata) {
                    acc.push(list[index].indexRank);
                }
                return acc;
            }, []);
            if (indices.length <= 3) {
                return indices.join('、');
            } else {
                const firstFour = indices.slice(0, 3);
                const remaining = indices.length - 3;
                return firstFour.join('、') + (remaining > 1 ? '等' : '等');
            }
        },
        // 结论5 计算各维度与上学期对比
        computedLastTerm(list, name) {
            let className = [];
            let comment = '';
            list.map(item => {
                if (item[`${name}ben`] > item[`${name}last`]) {
                    className.push(item.indexRank)
                }
            })
            if (className.length == list.length) {
                comment = '各年级都有所提升'
            } else {
                comment = `${className.join('、')}相较上次月测有所提升`
            }
            return comment
        },
        contrast(value, type) {
            if (type === '本次月测') {
                return value == null || value === 0 ? 0 : value;
            } else {
                return value == null || value === 0 ? '/' : value;
            }
        }
    },
    // 生命周期，创建完成时（可以访问当前this实例）
    created() {

    },
    // 生命周期：挂载完成时（可以访问DOM元素）
    mounted() {

    },
}
</script>
<style lang="less" scoped>
@import url("./less/monthTest.less");
</style>
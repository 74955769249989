<template>
  <div class="participation">
    <div class="Sittingposture" :id="'Child_t' + Gradelist[Grade]">
      <div class="cephalosome flex-align-between">
        <div class="Secondary_heading_Grade">
          <!-- <div class="vertical_grain_icon"></div> -->
          <span class="title">{{ Grade }}年级分析</span>
        </div>
      </div>
      <div class="cephalosome cephalosome1 flex-align-between">
        <div class="Secondary_heading">
          <div class="vertical_grain_icon"></div>
          <span class="title">{{ Grade }}书写目标</span>
        </div>
      </div>
      <div class="multiclass_analyse" v-if="table_type == '1'">
        <img src="../../../assets/newReport/one.png" alt="" v-if="Grade == '一年级' || Grade == '二年级'" />
        <img src="../../../assets/newReport/two.png" alt="" v-if="Grade == '三年级' || Grade == '四年级'" />
        <img src="../../../assets/newReport/three.png" alt="" v-if="Grade == '五年级' || Grade == '六年级'" />
      </div>
      <div class="multiclass_analyse" v-else>
        <img src="../../../assets/newReport/one_new.png" alt="" v-if="Grade == '一年级' || Grade == '二年级'" />
        <img src="../../../assets/newReport/two_new.png" alt="" v-if="Grade == '三年级' || Grade == '四年级'" />
        <img src="../../../assets/newReport/three_new.png" alt="" v-if="Grade == '五年级' || Grade == '六年级'" />
      </div>
      <div class="substance flex-between">
        <div class="excellent_rates">
          <h1>{{ schoolName }} {{ Grade }}各等级占比</h1>
          <!-- :high="Height4"
            :row_high="row_height4" -->
          <Short_form :construction="excellent_rates" :tabledata="excellent_rates_Data" :high="Height4"
            :row_high="row_height4" slashType="close">
          </Short_form>
        </div>
        <div class="excellent_rates_graphics">
          <h1>{{ schoolName }} {{ Grade }}各等级占比（%）</h1>
          <div class="excellent_ratesBox">
            <excellent_rates_graphics :graphicsData="excellent_contrast_graphics" :id_type="Gradelist[Grade] + 'zb'">
            </excellent_rates_graphics>
          </div>
        </div>
      </div>
      <div class="excellent_contrast">
        <h1>{{ Grade }}各等级与上学期对比情况</h1>
        <div class="hold_mistake_table table_scrollbar">
          <el-table :data="excellent_contrast_Data" style="width: 100%" :stripe="true" empty-text="暂无数据">
            <el-table-column prop="date" label="学期" align="center">
            </el-table-column>
            <el-table-column prop="level4" :label="table_type == '1' ? '优秀+' : '优秀'" align="center">
              <template slot-scope="scope">
                <div class="imgUrlbox">
                  <img :src="imgUrl[`imgUrl${scope.row.level4_compare}`]" alt="" />
                </div>
                <span style="margin-left: 10px">{{ scope.row.level4 }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="level3" :label="table_type == '1' ? '优秀' : '良好'" align="center">
              <template slot-scope="scope">
                <div class="imgUrlbox">
                  <img :src="imgUrl[`imgUrl${scope.row.level3_compare}`]" alt="" />
                </div>
                <span style="margin-left: 10px">{{ scope.row.level3 }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="level2" :label="table_type == '1' ? '良好' : '合格'" align="center">
              <template slot-scope="scope">
                <div class="imgUrlbox">
                  <img :src="imgUrl[`imgUrl${scope.row.level2_compare}`]" alt="" />
                </div>
                <span style="margin-left: 10px">{{ scope.row.level2 }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="level1" label="待提升" align="center">
              <template slot-scope="scope">
                <div class="imgUrlbox">
                  <img :src="imgUrl[`imgUrl${scope.row.level1_compare}`]" alt="" />
                </div>
                <span style="margin-left: 10px">{{ scope.row.level1 }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="level5" :label="table_type == '1' ? '优秀率(优秀+&优秀)' : '优良率（优秀&良好）'" align="center">
              <template slot-scope="scope">
                <div class="imgUrlbox">
                  <img :src="imgUrl[`imgUrl${scope.row.level5_compare}`]" alt="" />
                </div>
                <span style="margin-left: 10px">{{ scope.row.level5 }}</span>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div class="excellent_rates_conclusion conclusion" v-if="comment6">
        <span class="prefix_title">结论6</span>
        {{ Grade
        }}<span class="Comment_highlight">{{ comment6.k1 }}人数为{{ comment6.k2 }}</span>，基本达标；共有<span
          class="Comment_highlight">{{
            comment6.thisTerm[0].num
          }}</span>名学生属于{{ table_type == '1' ? '优秀+' : '优秀' }}，写的非常好；
        <span class="Comment_highlight">{{ comment6.thisTerm[1].num }}</span>名学生属于{{ table_type ==
          '1' ? '优秀' : '良好' }}，也很棒！整体{{ table_type == '1' ? '优秀率' : '优良率' }}为<span class="Comment_highlight">{{
    (comment6.thisTerm[0].proportion + comment6.thisTerm[1].proportion).toFixed(2)
  }}%</span>，对比上学期有所提升。
      </div>
    </div>
    <div class="Sittingposture" :id="'Child_b' + Gradelist[Grade]">
      <div class="cephalosome cephalosome1 flex-align-between">
        <div class="Secondary_heading">
          <div class="vertical_grain_icon"></div>
          <span class="title">{{ Grade }}各班{{ table_type == '1' ? '优秀率' : '优良率' }}对比</span>
        </div>
      </div>
      <div class="substance flex-between">
        <div class="excellent_rates">
          <!-- :high="Height6":row_high="row_height6" -->
          <Short_form :construction="excellent_rates_class" :tabledata="excellent_class_Data" slashType="close">
          </Short_form>
        </div>
        <div class="excellent_rates_graphics">
          <h1>{{ table_type == '1' ? '优秀率（优秀+&优秀）' : '优良率（优秀&良好）' }}</h1>
          <div class="excellent_ratesBox">
            <excellent_rates_graphicsTl :graphicsData="excellent_class_graphics" :id_type="Gradelist[Grade] + 'yx'">
            </excellent_rates_graphicsTl>
          </div>
        </div>
      </div>
      <div class="excellent_rates_conclusion conclusion" v-if="excellentClass.length > 0">
        <span class="prefix_title">结论7</span>{{ table_type == '1' ? '优秀率（含优秀+&优秀）' : '优良率（优秀&良好）' }}维度对比可见，
        <span v-clolrValue="{ value: excellentClass }"></span>
        {{ table_type == '1' ? '优秀率' : '优良率' }}较高。但是各班差距不是很大，只要注意方法练习，孩子们可塑性还是非常高的。
      </div>

    </div>
    <div class="Sittingposture" :id="'Child_i' + Gradelist[Grade]" v-if="workList.length > 0">
      <div class="outstanding_example" v-if="workList.length > 0">
        <div class="outstanding_tit flex-align-center">
          <img src="../../../assets/newReport/specimen.png" alt="" v-if="table_type == 1" />
          <img src="../../../assets/newReport/specimen2.png" alt="" v-else />
        </div>
        <div class="works flex-align-center">
          <el-image style="width: 550px" :src="item" v-for="(item, index) in workList" :key="index"
            :preview-src-list="workList">
          </el-image>
        </div>
        <div class="changeset flex-align-center">
          <div class="changeset_btn flex-align-center">换一组查看</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Short_form from "./Short_form.vue";
import excellent_rates_graphics from "./excellent_rates_graphics.vue";
import excellent_rates_graphicsTl from "./excellent_rates_graphicsTl.vue";
export default {
  //import引入组件才能使用
  components: {
    Short_form,
    excellent_rates_graphics,
    excellent_rates_graphicsTl
  },
  props: {
    Grade: String,
    schoolName: String,
    reportCode: String,
    termValue: Number,
    table_type: Number,
    correctReportType:Number
  },
  data() {
    return {
      //单项成绩各等级占比
      Gradelist: {
        一年级: 1,
        二年级: 2,
        三年级: 3,
        四年级: 4,
        五年级: 5,
        六年级: 6,
      },
      termValues: this.termValue,
      excellent_rates: [
        {
          fieldName: "date",
          title: "等级",
        },
        {
          fieldName: "level4",
          title: this.table_type == '1' ? "优秀+" : '优秀',
        },
        {
          fieldName: "level3",
          title: this.table_type == '1' ? "优秀" : '良好',
        },
        {
          fieldName: "level2",
          title: this.table_type == '1' ? "良好" : '合格',
        },
        {
          fieldName: "level1",
          title: "待提升",
        },
      ],
      excellent_rates_Data: [
        {
          date: "人数",
          level1: 0,
          level2: 0,
          level3: 0,
          level4: 0,
        },
        {
          date: "占比",
          level1: 0,
          level2: 0,
          level3: 0,
          level4: 0,
        },
      ],
      Height4: "255px",
      row_height4: "84px",
      //图例
      excellent_contrast_graphics: {
        idName: "excellent_contrast_graphics",
        title: "各等级占比（%）",
        yData: this.table_type == '1' ? ["优秀+", "优秀", "良好", "待提升"] : ["优秀", "良好", "合格", "待提升"],
        semester1: [0, 0, 0, 0],
        colordata: ["rgba(28, 141, 232, 1)", "rgba(97, 229, 238,1)"],
      },
      //年级各等级人数及优秀率与上学期对比情况
      excellent_contrast_Data: [{
        date: '本学期',
        level1: 0,
        level2: 0,
        level3: 0,
        level4: 0,
        level5: 0
      },
      {
        date: '上学期',
        level1: 0,
        level2: 0,
        level3: 0,
        level4: 0,
        level5: 0
      },
      ],
      //箭头类型
      imgUrl: {
        imgUrl1: require('@/assets/newReport/greent.png'),
        imgUrl2: require('@/assets/newReport/or_r.png'),
        imgUrl3: require('@/assets/newReport/redx.png'),
      },
      comment6: null,
      //各班优秀率对比
      excellent_rates_class: [{
        fieldName: 'className',
        title: '学期'
      },
      {
        fieldName: 'level4',
        title: this.table_type == '1' ? '优秀+' : '优秀'
      },
      {
        fieldName: 'level3',
        title: this.table_type == '1' ? '优秀' : '良好'
      },
      {
        fieldName: 'levelRatios',
        title: this.table_type == '1' ? '优秀率（优秀+&优秀）' : '优良率（优秀&良好）'
      },
      ],
      excellent_class_Data: [],
      Height6: '302',
      row_height6: '21',
      //图例
      excellent_class_graphics: {
        idName: 'excellent_class_graphics',
        title: this.table_type == '1' ? '优秀率（优秀+&优秀）' : '优良率（优秀&良好）',
        yData: ['一年级', '二年级', '三年级', '四年级'],
        semester1: [0, 0, 0, 0],
        colordata: [
          'rgba(233, 170, 19, 1)',
          'rgba(251, 238, 208,0.8)',
        ],
      },
      excellentClass: [],
      workList: [],
      limit: 2,
      pageSize: 1,
      // table_type: '',
    };
  },
  directives: {
    clolrValue: {
      inserted: function (el, binding) {
        let data = binding.value;
        if (data.value.length == 1) {
          if (data.value[0].levelRatio != 0) {
            el.innerHTML = '<span class="Comment_highlight">' + data.value[0].className + '</span>';
          } else {
            el.innerHTML = '<span class="Comment_highlight"> — — </span>';
          }
        }
        if (data.value.length == 2) {
          if (data.value[0].levelRatio != 0 && data.value[1].levelRatio != 0) {
            el.innerHTML = '<span class="Comment_highlight">' + data.value[0].className + '</span>和<span class="Comment_highlight"">' + data.value[1].className + '</span>';
          } else if ((data.value[0].levelRatio != 0 && data.value[1].levelRatio == 0)) {
            el.innerHTML = '<span class="Comment_highlight">' + data.value[0].className + '</span>';
          } else if ((data.value[0].levelRatio == 0 && data.value[1].levelRatio != 0)) {
            el.innerHTML = '<span class="Comment_highlight">' + data.value[1].className + '</span>';
          } else if ((data.value[0].levelRatio == 0 && data.value[1].levelRatio == 0)) {
            el.innerHTML = '<span class="Comment_highlight"> — — </span>';
          }
        }
      }
    }
  },
  // 计算属性
  computed: {},
  // 监听data中的数据变化
  watch: {},
  // 方法集合
  methods: {
    // 本年级各等级学生人数占比
    async gradeGradeRatio() {
      let data = {
        schoolId: this.$route.query.schoolId,
        termKey: this.termValues,
        grade: this.Gradelist[this.Grade],
        examType: this.correctReportType,
        // testTypeCode: this.reportCode
      };
      let res = this.table_type == 1 ? await this.$Api.Report.gradeGradeRatio(data) : await this.$Api.Report.gradeGradeRatioV2(data);
      let num = {
        date: "人数",
        level1: res.data.thisTerm[3].num,
        level2: res.data.thisTerm[2].num,
        level3: res.data.thisTerm[1].num,
        level4: res.data.thisTerm[0].num,
      };
      let rate = {
        date: "占比",
        level1: res.data.thisTerm[3].proportion + "%",
        level2: res.data.thisTerm[2].proportion + "%",
        level3: res.data.thisTerm[1].proportion + "%",
        level4: res.data.thisTerm[0].proportion + "%",
      };
      let thisTerm = {
        date: "本学期",
        level1: this.nullData(res.data.thisTerm[3].proportion),
        level2: this.nullData(res.data.thisTerm[2].proportion),
        level3: this.nullData(res.data.thisTerm[1].proportion),
        level4: this.nullData(res.data.thisTerm[0].proportion),
        // level5: (res.data.thisTerm[1].proportion + res.data.thisTerm[0].proportion).toFixed(2) + "%",
        level5: this.nullDataset(res.data.thisTerm[1].proportion,res.data.thisTerm[0].proportion),
        // 本学期与上学期对比 1高于 2相等 3低于
        level1_compare: this.compare_icon(res.data.thisTerm[3].proportion, res.data.lastTerm[3].proportion),
        level2_compare: this.compare_icon(res.data.thisTerm[2].proportion, res.data.lastTerm[2].proportion),
        level3_compare: this.compare_icon(res.data.thisTerm[1].proportion, res.data.lastTerm[1].proportion),
        level4_compare: this.compare_icon(res.data.thisTerm[0].proportion, res.data.lastTerm[0].proportion),
        level5_compare: this.compare_icon(res.data.thisTerm[1].proportion + res.data.thisTerm[0].proportion, res.data.lastTerm[1].proportion + res.data.lastTerm[0].proportion),
      }
      let lastTerm = {
        date: "上学期",
        level1: this.nullData(res.data.lastTerm[3].proportion),
        level2: this.nullData(res.data.lastTerm[2].proportion),
        level3: this.nullData(res.data.lastTerm[1].proportion),
        level4: this.nullData(res.data.lastTerm[0].proportion),
        // level5: (res.data.lastTerm[1].proportion + res.data.lastTerm[0].proportion).toFixed(2) + "%",
        level5: this.nullDataset(res.data.lastTerm[1].proportion,res.data.lastTerm[0].proportion),
      };
      // console.log('res.data.lastTerm[1].proportion + res.data.lastTerm[0].proportion',);
      
      //各等级占比
      this.excellent_rates_Data = [num, rate];
      //各等级与上学期对比情况
      this.excellent_contrast_Data = [thisTerm, lastTerm];
      let semester1 = [];
      res.data.thisTerm.map((item) => {
        semester1.push(item.proportion);
      });
      // 各等级占比（%）
      this.$set(this.excellent_contrast_graphics, "semester1", semester1);
      this.comment6 = res.data;
    },
    // 本年级各班优秀率对比
    gradeClassExcellentRateDiff() {
      let data = {
        schoolId: this.$route.query.schoolId,
        termKey: this.termValues,
        grade: this.Gradelist[this.Grade],
        examType: this.correctReportType,
        // testTypeCode: this.reportCode
      }
      this.$Api.Report.gradeClassExcellentRateDiff(data)
        .then(res => {
          let semester1 = [];
          let yData = [];
          let objArray = res.data.levelRatio;
          objArray = objArray.sort((a, b) => b.levelRatio - a.levelRatio);
          objArray.map(item => {
            item.levelRatios = item.levelRatio + '%';
            semester1.push(item.levelRatio);
            yData.push(item.className)
          })
          this.excellent_class_Data = objArray;
          this.$set(this.excellent_class_graphics, 'semester1', semester1);
          this.$set(this.excellent_class_graphics, 'yData', yData);
          let data = JSON.parse(JSON.stringify(res.data.levelRatio));
          // // 计算优秀率前2名
          data.sort((a, b) => parseFloat(b.levelRatio) - parseFloat(a.levelRatio));
          // // 获取前两个年级
          this.excellentClass = this.table_type === '1' ? data.slice(0, 2) : data.length < 3 ? data.slice(0, 1) : data.slice(0, 2);
        })
        .catch(err => {
          console.log('本年级各班优秀率对比err', err)
        })
    },
    // 优秀作品
    getWorks() {
      this.workList = [];
      let data = {
        schoolId: this.$route.query.schoolId,
        termKey: this.termValues,
        grade: this.Gradelist[this.Grade],
        size: this.limit,
        current: this.pageSize,
        examType: this.correctReportType,
        // testTypeCode: this.reportCode
      }
      this.$Api.Report.getWorks(data)
        .then(res => {
          // console.log('优秀作品:', res);
          if (res.data.records.length < this.limit) {
            this.current = 1;
          } else {
            this.current += 1;
          }
          res.data.records.map(item => {
            this.workList.push(item.fileUrl)
          })
        })
        .catch(err => {
          console.log('优秀作品err:', err)
        })
    },
    async getIsNewDIm() {
      let data = {
        termKey: this.termValues,
        schoolId: this.$route.query.schoolId
      }
      let resData = await this.$Api.DataScreen.getIsNewDIm(data);
      this.table_type = resData.data;
    },
    // 判断空数据
    nullData(value) {
      return !value && value != 0 ? '/' : value + '%';
    },
    nullDataset(value1, value2) {
      if (value1 == null && value2 != null) {
        return value2.toFixed(2) + "%";
      }else if (value1 != null && value2 == null) {
        return value1.toFixed(2) + "%";
      }else if (value1 != null && value2 != null) {
        return (value1+value2).toFixed(2) + "%";
      }else{
        return '/';
      }
      // return !value && value != 0 ? '/' : value + '%';
    },
    //返回箭头
    compare_icon(value1, value2) {
      if (value1 > value2) {
        return 1
      } else if (value1 < value2) {
        return 3
      } else {
        return 2
      }
    },

  },
  // 生命周期，创建完成时（可以访问当前this实例）
  created() { },
  // 生命周期：挂载完成时（可以访问DOM元素）
  mounted() {
    // console.log('termValue',this.termValues);
    // this.getIsNewDIm();
    this.gradeGradeRatio();
    this.gradeClassExcellentRateDiff();
    this.getWorks();
    // console.log('correctReportType',this.correctReportType);
    
  },
};
</script>
<style lang='less' scoped>
@import url("../less/frontBackaAppraisal.less");

.cephalosome1 {
  margin-top: 15px !important;
}
</style>
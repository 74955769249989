<template>
    <div class="participationRate" id="participationRate"></div>
</template>

<script>
export default {
    //import引入组件才能使用
    components: {},
    props: ['joinData', 'termKey','type'],
    data() {
        return {

        };
    },
    // 计算属性
    computed: {},
    // 监听data中的数据变化
    watch: {
        joinData: {
            handler() {
                this.participation_graphics()
            },
            deep: true,
        }
    },

    // 方法集合
    methods: {
        participation_graphics() {
            // console.log('joinData',this.joinData);
            this.$echarts.init(document.getElementById("participationRate")).dispose();
            let myChart = this.$echarts.init(
                document.getElementById("participationRate")
            );
            myChart.setOption({
                title: {
                    text: '各年级参与率',
                    fontWeight: 'bold',
                    top: '0%',
                    left: '38%',
                    textStyle: {
                        fontSize: this.graphicRatio(14),
                    }
                },
                tooltip: {
                    show: true,
                    textStyle: {
                        fontSize: this.graphicRatio(12),
                    },
                },
                legend: {
                    icon: 'rect',
                    show: true,
                    bottom: '0%',
                    // textStyle: {
                    color: ' #d3d3d3',
                    itemWidth: this.graphicRatio(22),
                    itemHeight: this.graphicRatio(14),
                    textStyle: {
                        fontSize: this.graphicRatio(12),
                    },
                    // },
                    itemGap: this.graphicRatio(30),
                },
                grid: [{
                    show: false,
                    top: '18%',
                    bottom: '8%',
                    containLabel: false,
                    height: '30%',
                },
                {
                    show: false,
                    top: '81%',
                    bottom: '8%',
                    height: '0%',
                },
                {
                    show: false,
                    bottom: '14%',
                    containLabel: false,
                    height: '30%',
                },
                ],
                xAxis: [{
                    type: 'category',
                    // data: ['一年级', '二年级', '三年级', '四年级', '五年级', '六年级'],
                    data: this.joinData.semester1.map(item => {
                        return item.name
                    }),
                    axisTick: {
                        show: false,
                        alignWithLabel: true,
                    },
                    splitLine: {
                        show: false,
                    },
                    axisLabel: {
                        margin: this.graphicRatio(12),
                        fontSize: this.graphicRatio(14),
                    },
                    axisLine: {
                        show: true,
                        lineStyle: {
                            // color: "#CECECE"
                            color: "#000",
                        }
                    },
                },
                {
                    type: 'category',
                    gridIndex: 1,
                    data: [],
                    axisLine: {
                        show: false,

                    },
                    axisTick: {
                        show: false,
                        alignWithLabel: true
                    },
                    axisLabel: {
                        show: false,
                    },
                    splitLine: {
                        show: false,
                    }
                },
                {
                    data: this.joinData.semester1.map(item => {
                        return item.name
                    }),
                    gridIndex: 2,
                    type: 'category',
                    axisLine: {
                        show: true,
                        lineStyle: {
                            // color: "#CECECE"
                            color: "#000"
                        }
                    },
                    axisTick: {
                        show: false,
                        inside: true,
                        alignWithLabel: true
                    },
                    axisLabel: {
                        show: false,

                    },
                    splitLine: {
                        show: false,
                    },
                },
                ],
                yAxis: [{
                    type: 'value',
                    // 整条y轴
                    axisLine: {
                        show: true,
                        lineStyle: {
                            // color: "#C2C2C2"
                            color: "#000",

                        }
                    },
                    // y轴上的小横线
                    axisTick: {
                        show: false
                    },
                    splitLine: {
                        show: false,
                    },
                    axisLabel: {
                        fontSize: this.graphicRatio(14),
                        // fontSize: 15,
                    }
                },
                {
                    gridIndex: 1,
                    type: 'value',
                    inverse: true,
                    axisLine: {
                        show: false,
                    },
                    axisTick: {
                        show: false,
                    },
                    axisLabel: {
                        show: false,
                    },
                    splitLine: {
                        show: false,
                    },
                },
                {
                    gridIndex: 2,
                    type: 'value',
                    inverse: true,
                    // 整条y轴
                    axisLine: {
                        show: true,
                        lineStyle: {
                            // color: "#C2C2C2"
                            color: "#000"
                        }
                    },
                    // y轴上的小横线
                    axisTick: {
                        show: false
                    },
                    splitLine: {
                        show: false,
                    },
                    axisLabel: {
                        fontSize: this.graphicRatio(14),
                        // fontSize: 15,
                    }
                },
                ],
                series: [{
                    name: this.type == 'month'? '本次月测': this.type == 'week'? '本次周测': '本学期',
                    type: 'bar',
                    barWidth: this.graphicRatio(25),
                    stack: '1',
                    itemStyle: {
                        borderRadius: [ this.graphicRatio(5), this.graphicRatio(5), 0, 0], // 设置圆角大小为10px,
                        color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                            offset: 0,
                            color: 'rgba(33, 146, 233, 1)'
                        }, {
                            offset: 1,
                            color: 'rgba(97, 229, 238,0.6)'
                        }])
                    },
                    data: this.joinData.semester1.map(item => {
                        return item.rate
                    }),
                    label: {
                        show: true,
                        position: 'top',
                        // color: "#CECECE",
                        fontSize: this.graphicRatio(14),
                        color: "#000",
                        formatter: (params) => {
                            return `${params.value} %`;
                        }
                    }
                },
                {
                    type: 'bar',
                    barWidth: this.graphicRatio(25),
                    stack: '1',
                    data: [],
                },
                {
                    name: this.type == 'month'? '上次月测': this.type == 'week'? '上次周测': '上学期',
                    // name: '上学期',
                    type: 'bar',
                    xAxisIndex: 2,
                    yAxisIndex: 2,
                    barWidth: this.graphicRatio(25),
                    stack: '2',
                    itemStyle: {
                        borderRadius: [0, 0, this.graphicRatio(5), this.graphicRatio(5)], // 设置圆角大小为10px,
                        color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                            offset: 0,
                            color: 'rgba(229, 235, 114,0.5)'
                        }, {
                            offset: 1,
                            color: 'rgba(7, 183, 139, 1)'
                        }])
                    },
                    data: this.joinData.semester2.map(item => {
                        return item.rate
                    }),
                    label: {
                        show: true,
                        position: 'bottom',
                        // color: "#CECECE",
                        fontSize: this.graphicRatio(14),
                        color: "#000",
                        formatter: (params) => {
                            return `${params.value} %`;
                        }
                    }

                },
                ],
            });
            window.addEventListener("resize", () => {
                myChart.resize();
            });
        },
    },
    // 生命周期，创建完成时（可以访问当前this实例）
    created() {

    },
    // 生命周期：挂载完成时（可以访问DOM元素）
    mounted() {

    },
}
</script>
<style scoped lang="less">
.participationRate {
    width: 100%;
    height: 100%;
}
</style>
<template>
    <div class="old_School_score">
        <div class="substance flex-align-between">
            <div class="performance_proportion table_scrollbar">
                <el-table :data="tablaData_school" style="width: 100%" :stripe="true" empty-text="暂无数据">
                    <el-table-column prop="indexRank" label="一级维度" align="center">
                    </el-table-column>
                    <el-table-column prop="parentId1" label="章法占格" align="center">
                        <template slot-scope="scope">
                            <div class="imgUrlbox" v-if="scope.row.parentId1_compare && scope.row.parentId1 != null">
                                <img :src="imgUrl[`imgUrl${scope.row.parentId1_compare}`]" alt="">
                            </div>
                            <span style="margin-left: 10px">{{ scope.row.parentId1 == null ? '/' :
                                scope.row.parentId1.toFixed(2) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="parentId2" label="结构组合" align="center">
                        <template slot-scope="scope">
                            <div class="imgUrlbox" v-if="scope.row.parentId2_compare && scope.row.parentId2 != null">
                                <img :src="imgUrl[`imgUrl${scope.row.parentId2_compare}`]" alt="">
                            </div>
                            <span style="margin-left: 10px">{{ scope.row.parentId2 == null ? '/' :
                                scope.row.parentId2.toFixed(2) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="parentId3" label="笔画形态" align="center">
                        <template slot-scope="scope">
                            <div class="imgUrlbox" v-if="scope.row.parentId3_compare && scope.row.parentId3 != null">
                                <img :src="imgUrl[`imgUrl${scope.row.parentId3_compare}`]" alt="">
                            </div>
                            <span style="margin-left: 10px">{{ scope.row.parentId3 == null ? '/' :
                                scope.row.parentId3.toFixed(2) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="parentId4" label="作品完整度" align="center">
                        <template slot-scope="scope">
                            <div class="imgUrlbox" v-if="scope.row.parentId4_compare && scope.row.parentId4 != null">
                                <img :src="imgUrl[`imgUrl${scope.row.parentId4_compare}`]" alt="">
                            </div>
                            <span style="margin-left: 10px">{{ scope.row.parentId4 == null ? '/' :
                                scope.row.parentId4.toFixed(2) }}</span>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div class="performance_graphics">
                <various_graphics :seriesData="seriesData" :variousData="variousData" :variousType="variousType">
                </various_graphics>
            </div>
        </div>
        <div class="semester flex-column-center">
            <h1>与上一学期测评对比</h1>
            <div class="semester_table table_scrollbar">
                <el-table :data="tableData_compare" style="width: 100%" :stripe="true" empty-text="暂无数据">
                    <el-table-column prop="indexRank" label="上学期对比" align="center"> </el-table-column>
                    <el-table-column label="章法占格" align="center">
                        <el-table-column prop="parentId1ben" label="本学期" align="center">
                            <template slot-scope="scope">
                                <div class="imgUrlbox"
                                    v-if="scope.row.parentId1_compare && scope.row.parentId1ben != null">
                                    <img :src="imgUrl[`imgUrl${scope.row.parentId1_compare}`]" alt="">
                                </div>
                                <span style="margin-left: 10px">{{ contrast(scope.row.parentId1ben, '本学期') }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="parentId1last" label="上学期" align="center">
                            <template slot-scope="scope">
                                <span>{{ contrast(scope.row.parentId1last, '上学期') }}</span>
                            </template>
                        </el-table-column>
                    </el-table-column>
                    <el-table-column label="结构组合" align="center">
                        <el-table-column prop="parentId2ben" label="本学期" align="center">
                            <template slot-scope="scope">
                                <div class="imgUrlbox"
                                    v-if="scope.row.parentId1_compare && scope.row.parentId2ben != null">
                                    <img :src="imgUrl[`imgUrl${scope.row.parentId1_compare}`]" alt="">
                                </div>
                                <span style="margin-left: 10px">{{ contrast(scope.row.parentId2ben, '本学期') }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="parentId2last" label="上学期" align="center">
                            <template slot-scope="scope">
                                <span>{{ contrast(scope.row.parentId2last, '上学期') }}</span>
                            </template>
                        </el-table-column>
                    </el-table-column>
                    <el-table-column label="笔画形态" align="center">
                        <el-table-column prop="parentId3ben" label="本学期" align="center">
                            <template slot-scope="scope">
                                <div class="imgUrlbox"
                                    v-if="scope.row.parentId1_compare && scope.row.parentId3ben != null">
                                    <img :src="imgUrl[`imgUrl${scope.row.parentId1_compare}`]" alt="">
                                </div>
                                <span style="margin-left: 10px">{{ contrast(scope.row.parentId3ben, '本学期') }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="parentId3last" label="上学期" align="center">
                            <template slot-scope="scope">
                                <span>{{ contrast(scope.row.parentId3last, '上学期') }}</span>
                            </template>
                        </el-table-column>
                    </el-table-column>
                    <el-table-column label="作品完整度" align="center">
                        <el-table-column prop="parentId4ben" label="本学期" align="center">
                            <template slot-scope="scope">
                                <div class="imgUrlbox"
                                    v-if="scope.row.parentId1_compare && scope.row.parentId4ben != null">
                                    <img :src="imgUrl[`imgUrl${scope.row.parentId1_compare}`]" alt="">
                                </div>
                                <span style="margin-left: 10px">{{ contrast(scope.row.parentId4ben, "本学期") }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="parentId4last" label="上学期" align="center">
                            <template slot-scope="scope">
                                <span>{{ contrast(scope.row.parentId4last, "上学期") }}</span>
                            </template>
                        </el-table-column>
                    </el-table-column>
                </el-table>
            </div>
            <div class="semester_conclusion conclusion" v-if="comment5">
                <span class="prefix_title">结论5</span><br />
                <span>(1) 章法占格维度：平均成绩处于<span class="Comment_highlight">{{ comment5.k1 }}</span>水平，
                    <span class="Comment_highlight">{{
                        computedComment5(JSON.parse(JSON.stringify(comment5.resultDiffList)),
                            'parentId1ben') }}较好</span>。
                    <br />(2) 结构组合维度：平均成绩处于<span class="Comment_highlight">{{ comment5.k3 }}</span>水平，
                    <span class="Comment_highlight">{{
                        computedComment5(JSON.parse(JSON.stringify(comment5.resultDiffList)),
                            'parentId2ben') }}较好</span>。
                    <br />(3) 笔画形态维度：平均成绩处于<span class="Comment_highlight">{{ comment5.k5 }}</span>水平，
                    <span class="Comment_highlight">{{
                        computedComment5(JSON.parse(JSON.stringify(comment5.resultDiffList)),
                            'parentId3ben') }}较好</span>。
                    <br />(4) 作品完整度维度：平均成绩处于<span class="Comment_highlight">{{ comment5.k7 }}</span>水平，
                    <span class="Comment_highlight">{{
                        computedComment5(JSON.parse(JSON.stringify(comment5.resultDiffList)),
                            'parentId4ben') }}较好</span>。
                </span>
                <p>相较上个学期，在
                    <span class="Comment_highlight"> 章法占格 </span>维度，
                    {{ computedLastTerm(comment5.resultDiffList, 'parentId1') }}；
                    <span class="Comment_highlight">结构组合</span>维度，
                    {{ computedLastTerm(comment5.resultDiffList, 'parentId2') }}；
                    <span class="Comment_highlight">笔画形态</span>维度，
                    {{ computedLastTerm(comment5.resultDiffList, 'parentId3') }}；
                    <span class="Comment_highlight">作品完整度</span>维度，
                    {{ computedLastTerm(comment5.resultDiffList, 'parentId4') }}。
                </p>
            </div>
        </div>
    </div>
</template>

<script>
import various_graphics from '../appraisalCharts/various_graphics.vue'
export default {
    //import引入组件才能使用
    components: {
        various_graphics
    },
    props: ['tablaData_school', 'seriesData', 'variousData', 'variousType', 'tableData_compare', 'comment5'],
    data() {
        return {
            // comment5:'',
            imgUrl: {
                imgUrl1: require('@/assets/newReport/greent.png'),
                imgUrl2: require('@/assets/newReport/or_r.png'),
                imgUrl3: require('@/assets/newReport/redx.png'),
            },
        };
    },
    // 计算属性
    computed: {},
    // 监听data中的数据变化
    watch: {},
    // 方法集合
    methods: {
        // 结论5 计算各维度成绩前两名
        computedComment5(list, name) {
            list.sort((a, b) => b[name] - a[name]);
            try {
                return `${list[0].indexRank}`

            } catch (err) {
                console.log('计算结论5出报错:', err)
            }

        },
        // 结论5 计算各维度与上学期对比
        computedLastTerm(list, name) {
            let className = [];
            let comment = '';
            list.map(item => {
                if (item[`${name}ben`] > item[`${name}last`]) {
                    className.push(item.indexRank)
                }
            })
            if (className.length == list.length) {
                comment = '各年级都有所提升'
            } else {
                comment = `${className.join('、')}相较上个学期有所提升`
            }
            return comment
        },
        contrast(value, type) {
            return value == null? '/': value;
            // if (type === '本学期') {
            //     return value == null? '/': value;
            // } else {
            //     return value == null? '/' : value;
            // }
        }
    },
    // 生命周期，创建完成时（可以访问当前this实例）
    created() {

    },
    // 生命周期：挂载完成时（可以访问DOM元素）
    mounted() {

    },
    beforeCreate() { },//生命周期：创建之前
    beforeMount() { },//生命周期：挂载之前
    beforeUpdate() { },//生命周期：更新之前
    updated() { },//生命周期：更新之后
    beforeDestroy() { },//生命周期：销毁之前
    destroyed() { },//生命周期：销毁完成
    activated() { },//如果页面有keep-alive缓存功能，这个函数会触发执行
}
</script>
<style scoped lang="less" scoped>
@import url("../less/frontBackaAppraisal.less");
</style>
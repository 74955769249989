<!-- 全校各等级学员占比(新版) -->
<template>
    <div class="schoolLevel">
        <el-table :data="newComment" style="width: 100%" border>
            <el-table-column label="等级" align="center">
                <template slot-scope="scope">
                    {{ level[scope.row.level] }}
                </template>
            </el-table-column>
            <el-table-column label="居中占位" align="center">
                <template slot-scope="scope">
                    {{ scope.row.center_pos }}%
                </template>
            </el-table-column>
            <el-table-column label="占格大小" align="center">
                <template slot-scope="scope">
                    {{ scope.row.grid_size }}%
                </template>
            </el-table-column>
            <el-table-column label="字形重心" align="center">
                <template slot-scope="scope">
                    {{ scope.row.glyph_grav }}%
                </template>
            </el-table-column>
            <el-table-column label="组合关系" align="center">
                <template slot-scope="scope">
                    {{ scope.row.combo_rel }}%
                </template>
            </el-table-column>
            <el-table-column label="收放关系" align="center">
                <template slot-scope="scope">
                    {{ scope.row.rewind_rel }}%
                </template>
            </el-table-column>
            <el-table-column label="多画等距" align="center">
                <template slot-scope="scope">
                    {{ scope.row.multi_dist }}%
                </template>
            </el-table-column>
            <el-table-column label="笔画关系" align="center">
                <template slot-scope="scope">
                    {{ scope.row.stroke_rel }}%
                </template>
            </el-table-column>
            <el-table-column label="作品完整度" align="center">
                <template slot-scope="scope">
                    {{ scope.row.integrity }}%
                </template>
            </el-table-column>
        </el-table>
    </div>
</template>
<script>
export default {
    props: ['termValue', 'reportType', 'reportCode','newComment'],
    data() {
        return {
            level: {
                '4': '优秀',
                '3': '良好',
                '2': '合格',
                '1': '待提升',
            }
        }
    },
    methods: {
    },
    mounted() { }
}
</script>
<style lang="less" scoped>
</style>